<template>
  <v-app>
    <v-main class="primary">
      <v-container fill-height>
        <v-layout align-center justify-center>
          <v-flex>
            <v-row justify="center">
              <v-col cols="12" sm="10" md="8" lg="6" xl="4">
                <v-card class="px-5" tile>
                  <v-card-text class="text-left py-10 px-15">
                    <div class="primary--text text-h3 text-center">
                      สมัครใช้งานฟรี
                    </div>
                    <div class="pt-5 pb-7">
                      <v-btn
                        color="error"
                        block
                        href="https://manual.permtang.com/documentation/setting/"
                        target="_blank"
                      >
                        <v-icon left large class="pr-5">
                          mdi-book-open-variant
                        </v-icon>
                        คู่มือการตั้งค่าโปรแกรม
                      </v-btn>
                    </div>

                    <v-text-field
                      class="mb-0"
                      label="ชื่อจริง หรือ ชื่อเล่น"
                      background-color="white"
                      outlined
                      dense
                      name="name"
                      v-model="formSubmit.name"
                      :error-messages="
                        $v.formSubmit.name.$error ? $error_text : ''
                      "
                    >
                    </v-text-field>

                    <v-text-field
                      class="mb-0"
                      label="Username สำหรับล็อกอินเข้าสู่ระบบ (ภาษาอังกฤษเท่านั้น)"
                      v-on:keypress="isLetter($event)"
                      background-color="white"
                      outlined
                      dense
                      name="username"
                      v-model="formSubmit.username"
                      :error-messages="
                        $v.formSubmit.username.$error ? $error_text : ''
                      "
                    >
                    </v-text-field>

                    <v-text-field
                      class="mb-0"
                      label="รหัสผ่าน (ตัวอักษร และตัวเลข อย่างน้อย 8 ตัว)"
                      background-color="white"
                      outlined
                      dense
                      :type="showPassword ? 'text' : 'password'"
                      name="password"
                      v-model="formSubmit.password"
                      :error-messages="
                        $v.formSubmit.password.$error
                          ? 'กรุณาใส่รหัสผ่านอย่างน้อย 8 ตัว'
                          : ''
                      "
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      @keypress="passwordCharacter()"
                    >
                    </v-text-field>

                    <v-text-field
                      class="mb-0"
                      label="ยืนยันรหัสผ่าน"
                      background-color="white"
                      outlined
                      dense
                      :type="showConfirmPassword ? 'text' : 'password'"
                      name="confirm_password"
                      v-model="form.confirm_password"
                      :error-messages="
                        $v.form.confirm_password.$error
                          ? 'รหัสผ่านไม่ตรงกัน'
                          : ''
                      "
                      :append-icon="
                        showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      @click:append="showConfirmPassword = !showConfirmPassword"
                      @keypress="passwordCharacter()"
                    >
                    </v-text-field>

                    <v-text-field
                      class="mb-0"
                      label="เบอร์ติดต่อ"
                      background-color="white"
                      outlined
                      dense
                      name="phone_number"
                      v-model="formSubmit.phone_number"
                      :error-messages="
                        $v.formSubmit.phone_number.$error ? $error_text : ''
                      "
                    >
                    </v-text-field>

                    <v-text-field
                      class="mb-0"
                      label="อีเมลล์"
                      background-color="white"
                      outlined
                      dense
                      name="email"
                      v-model="formSubmit.email"
                      :error-messages="
                        $v.formSubmit.email.$error ? $error_text : ''
                      "
                    >
                    </v-text-field>

                    <v-layout justify-space-between>
                      <!-- <v-checkbox class="mt-0" label="Remember me" color="app_primary"></v-checkbox> -->
                      <v-checkbox
                        color="app_primary"
                        :value="true"
                        v-model="form.is_accept"
                        :error-messages="
                          $v.form.is_accept.$error ? 'Agree to continue.' : ''
                        "
                        :disabled="disabled"
                        :loading="isLoading"
                      >
                        <template v-slot:label>
                          <div>
                            ได้อ่าน ยอมรับ
                            <v-tooltip>
                              <template v-slot:activator="{ on }">
                                <a
                                  target="_blank"
                                  href="https://permtang.com/term-of-use/"
                                  @click.stop
                                  v-on="on"
                                >
                                  เงื่อนไขให้บริการ
                                </a>
                              </template>
                            </v-tooltip>
                            และ
                            <v-tooltip>
                              <template v-slot:activator="{ on }">
                                <a
                                  target="_blank"
                                  href="https://permtang.com/privacy-policy/"
                                  @click.stop
                                  v-on="on"
                                >
                                  นโยบายความเป็นส่วนตัว
                                </a>
                              </template>
                            </v-tooltip>
                            แล้ว
                          </div>
                        </template>
                      </v-checkbox>
                    </v-layout>

                    <v-btn
                      color="success"
                      elevation="0"
                      block
                      @click="register()"
                      :disabled="loading"
                      :loading="loading"
                    >
                      รับสิทธิ์ทดลองใช้งานฟรี
                    </v-btn>

                    <span class="red--text" v-if="registerError">{{
                      registerError
                    }}</span>

                    <div class="mt-5 text-center mb-10">
                      <span class="mr-5 text-h5">มีบัญชีกับเราแล้ว ?</span>
                      <router-link to="/login" class="text-h5 primary--text"
                        >ล็อกอินเข้าสู่ระบบ</router-link
                      >
                    </div>

                    <v-divider />
                    <div class="text-right mt-2">
                      Line id: @P2277
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <div class="text-center font-weight-light white--text mt-10">
              2020 YAKYAI SOFT .CO.LTD . ALL RIGHT RESERVE
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {
  required,
  email,
  sameAs,
  checked,
  numeric,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';
export default {
  name: 'register',

  data: () => ({
    showPassword: false,
    showConfirmPassword: false,
    registerError: false,
    loading: false,

    formSubmit: {
      username: '',
      name: '',
      phone_number: '',
      email: '',
      password: '',
    },
    form: {
      is_accept: false,
      confirm_password: '',
    },
    showPopupTerm: false,
    showPopupPolicy: false,
    disabled: false,
    isLoading: false,
  }),

  validations: {
    formSubmit: {
      username: { required },
      name: { required },
      email: { required, email },
      password: { required, minLength: minLength(8) },
      phone_number: {
        required,
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(10),
      },
    },
    form: {
      confirm_password: {
        required,
        sameAsPassword: sameAs(function () {
          return this.formSubmit.password;
        }),
      },
      is_accept: {
        required,
        checked: (value) => {
          return value === true;
        },
      },
    },
  },

  methods: {
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    register: async function () {
      this.registerError = '';
      this.$v.form.$touch();
      this.$v.formSubmit.$touch();

      if (this.$v.form.$invalid || this.$v.formSubmit.$invalid) return;

      this.loading = true;
      this.isLoading = true;
      let body = {
        token: this.$jwt.sign(this.formSubmit, this.$privateKey, {
          noTimestamp: true,
        }),
      };

      await this.$axios
        .post(`${this.$baseUrl}/users/create_user`, body)
        .then(async (res) => {
          if (!res.result) {
            this.registerError = res.status;
          } else {
            let body_authen = {
              token: this.$jwt.sign(
                {
                  username: this.formSubmit.username,
                  password: this.formSubmit.password,
                },
                this.$privateKey,
                { noTimestamp: true }
              ),
            };

            await this.$axios
              .post(`${this.$baseUrl}/users/authen`, body_authen)
              .then(async (res) => {
                await localStorage.setItem(
                  'access_token',
                  `Bearer ${res.result}`
                );

                // await this.$axios.setHeader(
                //   'Authorization',
                //   localStorage.getItem('access_token')
                // );
                // this.$router.push('/pages/product/product');

                await this.$swal({
                  title: 'กำลัง สมัครสมาชิก',
                  html: 'กรุณารอสักครู่...ใช้เวลา 30 วินาที', // add html attribute if you want or remove
                  timer: 28000,
                  allowOutsideClick: false,
                  onBeforeOpen: () => {
                    this.$swal.showLoading();
                  },
                });
                this.$swal({
                  title: `สมัครสมาชิกสำเร็จ`,
                  text: `กดตกลง เพื่อเข้าสู่ระบบ`,
                  icon: 'success',
                  confirmButtonText: 'ตกลง',
                  cancelButtonText: 'ปิด',
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  showCancelButton: false,
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    window.location = '/pages/home';
                  } else {
                    window.location = '/login';
                  }
                });

                // this.$alertSuccess({
                //   title: 'สมัครสำเร็จแล้ว กำลังนำท่านไปหน้าล็อกอิน โปรดรอสักครู่',
                //   timer: 1000,
                // });
                // setTimeout(() => {}, 1000);
                // window.open(
                //   'https://manual.permtang.com/documentation/setting/',
                //   '_blank'
                // );
                // window.location = '/login';
              })
              .catch((err) => {
                this.registerError = err.error_message;
                //register
              });
            this.loading = false;
          }
        })
        .catch((err) => {
          this.registerError = err.error_message;
        })
        .then(() => {
          this.loading = false;
          this.isLoading = false;
        });
    },
    passwordCharacter(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode >= 48 && charCode <= 57) {
        return true;
      } else if (charCode >= 65 && charCode <= 90) {
        return true;
      } else if (charCode >= 97 && charCode <= 122) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.subtitle-text {
  font-size: 20px !important;
}
</style>
